<div class="select__lang">
  <select #selectedLang (change)="switchLang(selectedLang.value)">
    <option
      *ngFor="let language of translate.getLangs()"
      [value]="language"
      [selected]="language === translate.currentLang"
      class="box"
    >
      {{ language | titlecase }}
    </option>
  </select>
  <div class="arrow">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M10 13.75L3.75 7.5L4.625 6.625L10 12L15.375 6.625L16.25 7.5L10 13.75Z"
        fill="#111111"
      />
    </svg>
  </div>
</div>
