import { Component, Input } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { FormControl } from '@angular/forms'
import { AppState } from 'src/app/core/store/app.reducers'
import { Store } from '@ngrx/store'
import { fromAppSelector } from 'src/app/core/store'

@Component({
  selector: 'app-input-with-icon',
  templateUrl: './input-with-icon.component.html',
  styleUrls: ['./input-with-icon.component.scss']
})
export class InputWithIconComponent {
  constructor(
    public translate: TranslateService,
    private store: Store<AppState>
  ) {}

  @Input() control: FormControl = new FormControl()
  @Input() type: string = ''
  @Input() placeholder: string = ''
  @Input() required: boolean = false
  @Input() readonly: boolean = false
  @Input() darker = false
  @Input() icon = ''

  @Input() id: string = ''
  isDarkTheme$ = this.store.select(fromAppSelector.getAppTheme)

  @Input() isMultiline = false

  togglePasswordField = false

  displayErrors() {
    const { dirty, touched, errors } = this.control
    return dirty && touched && errors
  }

  showPassword(): void {
    this.togglePasswordField = !this.togglePasswordField
  }
}
