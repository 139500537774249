import { inject } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { AppActions } from '.'
import { tap } from 'rxjs'
import { UserKeys } from '../constants/constants'
import { LocalStorageService } from '../services/localStorage.service'

export const tokens = createEffect(
  (
    actions$ = inject(Actions),
    localStorageService = inject(LocalStorageService)
  ) =>
    actions$.pipe(
      ofType(AppActions.tokens),
      tap((action) => {
        localStorageService.setAccessToken(action.tokens.accessToken)
        localStorageService.setRefreshToken(action.tokens.refreshToken)
      })
    ),
  { functional: true, dispatch: false }
)

export const theme = createEffect(
  (
    actions$ = inject(Actions),
    localStorageService = inject(LocalStorageService)
  ) =>
    actions$.pipe(
      ofType(AppActions.switchTheme),
      tap((action) => {
        localStorageService.setItem(UserKeys.IS_DARK_THEME, action.isDarkTheme)
      })
    ),
  { functional: true, dispatch: false }
)

export const media = createEffect(
  (actions$ = inject(Actions)) => actions$.pipe(ofType(AppActions.setMedia)),
  { functional: true, dispatch: false }
)

export const currentUser = createEffect(
  (
    actions$ = inject(Actions),
    localStorageService = inject(LocalStorageService)
  ) =>
    actions$.pipe(
      ofType(AppActions.setCurrentUser),
      tap((user) => {
        localStorageService.setItem(UserKeys.CURRENT_USER, user.user)
      })
    ),
  { functional: true, dispatch: false }
)

export const selectedSessions = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(ofType(AppActions.setSelectedSessions)),
  { functional: true, dispatch: false }
)

export const isCreatingPassword = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(ofType(AppActions.isCreatingPassword)),
  { functional: true, dispatch: false }
)

export const addExperience = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(ofType(AppActions.addExperiences)),
  { functional: true, dispatch: false }
)

export const addStudies = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(ofType(AppActions.addExperiences)),
  { functional: true, dispatch: false }
)

export const editExperience = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(ofType(AppActions.editExperience)),
  { functional: true, dispatch: false }
)

export const editSudy = createEffect(
  (actions$ = inject(Actions)) => actions$.pipe(ofType(AppActions.editStudy)),
  { functional: true, dispatch: false }
)

export const updateExperiences = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(ofType(AppActions.updateExperiences)),
  { functional: true, dispatch: false }
)

export const tutors = createEffect(
  (actions$ = inject(Actions)) => actions$.pipe(ofType(AppActions.setTutors)),
  { functional: true, dispatch: false }
)

export const isSearchingTutor = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(ofType(AppActions.initiateSearchTutor)),
  { functional: true, dispatch: false }
)

export const addSchedule = createEffect(
  (actions$ = inject(Actions)) => actions$.pipe(ofType(AppActions.addSchedule)),
  { functional: true, dispatch: false }
)

export const setSelectedTimeSlot = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(ofType(AppActions.setSelectedTimeSlot)),
  { functional: true, dispatch: false }
)

export const currentChatPeer = createEffect(
  (
    actions$ = inject(Actions),
    localStorageService = inject(LocalStorageService)
  ) =>
    actions$.pipe(
      ofType(AppActions.setCurrentChatPeer),
      tap((action) => {
        localStorageService.setItem(
          UserKeys.CURRENT_PEER_CHAT,
          action.currentChatPeer
        )
      })
    ),
  { functional: true, dispatch: false }
)
