<div
  class="input-field"
  [class.invalid]="control.invalid && displayErrors()"
  [class.darktheme]="(isDarkTheme$ | async) && darker"
  [class.noIcon]="icon === ''"
>
  <div *ngIf="icon !== ''" class="icon">
    <img [src]="icon" alt="" loading="lazy" />
  </div>

  <input
    [type]="type === 'password' ? (togglePasswordField ? 'text' : type) : type"
    [formControl]="control"
    [required]="required"
    [readonly]="readonly"
    placeholder=" "
    *ngIf="!isMultiline"
  />

  <textarea
    *ngIf="isMultiline"
    [formControl]="control"
    [required]="required"
    [readonly]="readonly"
    placeholder=" "
  ></textarea>

  <label class="label">
    <span class="required" *ngIf="required">*</span
    ><span>{{ placeholder | translate }}</span
    ><span class="optional" *ngIf="!required"
      >({{ 'shared.labels.optional' | translate }})</span
    >
  </label>
  <div *ngIf="type === 'password'" (click)="showPassword()" class="password">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      *ngIf="!togglePasswordField"
    >
      <path
        d="M2.5 10.8335C5.5 4.16683 14.5 4.16683 17.5 10.8335"
        stroke="#111111"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10 14.167C9.6717 14.167 9.34661 14.1023 9.04329 13.9767C8.73998 13.8511 8.46438 13.6669 8.23223 13.4348C8.00009 13.2026 7.81594 12.927 7.6903 12.6237C7.56466 12.3204 7.5 11.9953 7.5 11.667C7.5 11.3387 7.56466 11.0136 7.6903 10.7103C7.81594 10.407 8.00009 10.1314 8.23223 9.89922C8.46438 9.66708 8.73998 9.48293 9.04329 9.35729C9.34661 9.23166 9.6717 9.16699 10 9.16699C10.663 9.16699 11.2989 9.43038 11.7678 9.89922C12.2366 10.3681 12.5 11.004 12.5 11.667C12.5 12.33 12.2366 12.9659 11.7678 13.4348C11.2989 13.9036 10.663 14.167 10 14.167Z"
        stroke="#111111"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      *ngIf="togglePasswordField"
    >
      <path
        d="M3.31657 5.8525C2.53695 6.77265 1.95642 7.84434 1.61157 9C2.68824 12.615 6.03657 15.25 9.99991 15.25C10.8274 15.25 11.6274 15.135 12.3857 14.9208M5.18991 4.19C6.61709 3.24817 8.28997 2.74735 9.99991 2.75C13.9632 2.75 17.3107 5.385 18.3874 8.99833C17.7973 10.9727 16.5307 12.6763 14.8099 13.81M5.18991 4.19L2.49991 1.5M5.18991 4.19L8.23157 7.23167M14.8099 13.81L17.4999 16.5M14.8099 13.81L11.7682 10.7683C12.0004 10.5362 12.1846 10.2605 12.3102 9.95721C12.4359 9.65387 12.5005 9.32875 12.5005 9.00042C12.5005 8.67208 12.4359 8.34697 12.3102 8.04363C12.1846 7.74029 12.0004 7.46467 11.7682 7.2325C11.5361 7.00033 11.2605 6.81617 10.9571 6.69052C10.6538 6.56487 10.3287 6.5002 10.0003 6.5002C9.67199 6.5002 9.34687 6.56487 9.04353 6.69052C8.74019 6.81617 8.46457 7.00033 8.23241 7.2325M11.7674 10.7675L8.23324 7.23333"
        stroke="#111111"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</div>
<div *ngIf="displayErrors()" class="errors">
  <div *ngIf="control.errors?.['required']!">
    {{ 'shared.validationErrors.required' | translate }}
  </div>
  <div *ngIf="control.errors?.['email']!">
    {{ 'shared.validationErrors.email' | translate }}
  </div>
  <div *ngIf="control.errors?.['pattern']!">
    {{
      type === 'email'
        ? ('shared.validationErrors.email' | translate)
        : ('shared.validationErrors.password' | translate)
    }}
  </div>
  <div *ngIf="control.errors?.['minlength']!">
    {{ 'shared.validationErrors.minLength.left' | translate }}
    {{ control.errors?.['minlength']!.actualLength }}
    {{ 'shared.validationErrors.minLength.right' | translate }}
    {{ control.errors?.['minlength']!.requiredLength }}
  </div>
  <div *ngIf="control.errors?.['min']!">
    {{ 'shared.validationErrors.min' | translate }}
  </div>
</div>
