import { isPlatformBrowser } from '@angular/common'
import {
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
  ViewChild
} from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { ToastContainerDirective, ToastrService } from 'ngx-toastr'
import { AppState } from './core/store/app.reducers'
import { Store } from '@ngrx/store'
import { LocalStorageService } from './core/services/localStorage.service'
import { UserKeys } from './core/constants/constants'
import { AppActions } from './core/store'
import { CurrentUser } from './features/home/@types/current-user'
import { PeerChat } from './features/home/@types/chat'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(
    public translate: TranslateService,
    private toastrService: ToastrService,
    private store: Store<AppState>,
    private localStorageService: LocalStorageService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    const lang = isPlatformBrowser(this.platformId)
      ? localStorage.getItem(UserKeys.LANG)
        ? localStorage.getItem(UserKeys.LANG)
        : translate.getBrowserLang()
      : translate.getBrowserLang()

    switch (lang) {
      case 'en-GB':
      case 'en':
      case 'en-US':
        translate.addLangs(['en', 'fr'])
        translate.setDefaultLang('en')
        break
      case 'fr-FR':
      case 'fr':
      case 'fr-CA':
        translate.addLangs(['fr', 'en'])
        translate.setDefaultLang('fr')
        break
      default:
        translate.setDefaultLang('fr')
        break
    }
  }

  @ViewChild(ToastContainerDirective, { static: true })
  toastContainer!: ToastContainerDirective

  ngOnInit() {
    this.toastrService.overlayContainer = this.toastContainer

    const theme =
      this.localStorageService.getItem(UserKeys.IS_DARK_THEME)! ?? false

    const currentChatPeer = this.localStorageService.getItem(
      UserKeys.CURRENT_PEER_CHAT
    )!

    const currentUser = this.localStorageService.getItem(UserKeys.CURRENT_USER)!

    this.store.dispatch(AppActions.switchTheme({ isDarkTheme: !!theme }))
    this.store.dispatch(
      AppActions.setCurrentChatPeer({
        currentChatPeer: currentChatPeer ? (currentChatPeer as PeerChat) : null
      })
    )
    this.store.dispatch(
      AppActions.setCurrentUser({
        user: currentUser as CurrentUser
      })
    )
  }
}
