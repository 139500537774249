import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { TokenPair } from '../@types/token-pair'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
import {
  SignUpDto,
  ResetPasswordDto,
  SignInDto,
  ResendVerificationOtp
} from '../@types/auth.dto'

@Injectable({ providedIn: 'root' })
export class AuthService {
  constructor(private http: HttpClient) {}

  authUri = `${environment.BASE_URL}/auth`

  signIn(credential: SignInDto): Observable<TokenPair> {
    return this.http.post<TokenPair>(`${this.authUri}/signin`, credential)
  }

  signInWithPhoneNumber(credential: SignInDto): Observable<TokenPair> {
    return this.http.post<TokenPair>(`${this.authUri}/phone/signin`, credential)
  }

  signUp(credential: SignUpDto): Observable<TokenPair> {
    return this.http.post<TokenPair>(`${this.authUri}/signup`, credential)
  }

  signUpWithPhoneNumber(credential: SignUpDto): Observable<TokenPair> {
    return this.http.post<TokenPair>(`${this.authUri}/phone/signup`, credential)
  }

  sendVerificationOtp(data: ResendVerificationOtp) {
    return this.http.post(`${this.authUri}/send-verification-otp`, data)
  }

  verifyAccount(otp: string): Observable<TokenPair> {
    return this.http.patch<TokenPair>(`${this.authUri}/verify-account`, { otp })
  }

  refreshTokens(): Observable<TokenPair> {
    return this.http.get<TokenPair>(`${this.authUri}/refresh-tokens`)
  }

  resetPassword(data: ResetPasswordDto) {
    return this.http.patch(`${this.authUri}/reset-password?otp=${data.otp}`, {
      password: data.password,
      confirmPassword: data.confirmPassword
    })
  }

  validateResetPasswordOtp(otp: string) {
    return this.http.post(`${this.authUri}/validate-reset-password-otp`, {
      otp
    })
  }

  sendResetPasswordOtp(data: ResendVerificationOtp) {
    return this.http.post(`${this.authUri}/send-reset-password-otp`, data)
  }

  isAuthenticated(): Observable<boolean> {
    return this.http.get<boolean>(this.authUri)
  }

  generate2FA() {
    return this.http.patch(
      `${this.authUri}/2fa/generate`,
      {},
      { responseType: 'blob' }
    )
  }

  disable2FA() {
    return this.http.patch(`${this.authUri}/2fa/disable`, {})
  }
}
