import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { InputWithIconComponent } from './components/input-with-icon/input-with-icon.component'
import { SwitchLangComponent } from './components/switch-lang/switch-lang.component'
import { TranslateModule } from '@ngx-translate/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { SpinnerComponent } from './components/spinner/spinner.component'
import { SearchCountryComponent } from './components/search-country/search-country.component'
import { MediaModalComponent } from './components/media-modal/media-modal.component'
import { NgxDocViewerModule } from 'ngx-doc-viewer'

@NgModule({
  declarations: [
    InputWithIconComponent,
    SwitchLangComponent,
    SpinnerComponent,
    SearchCountryComponent,
    MediaModalComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    NgxDocViewerModule
  ],
  exports: [
    SwitchLangComponent,
    InputWithIconComponent,
    SpinnerComponent,
    SearchCountryComponent,
    MediaModalComponent
  ]
})
export class SharedModule {}
