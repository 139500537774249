import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { BehaviorSubject, Observable } from 'rxjs'
import { AppState } from 'src/app/core/store/app.reducers'

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private display: BehaviorSubject<any> = new BehaviorSubject('close')

  watch(): Observable<'open' | 'close'> {
    return this.display.asObservable()
  }
  open() {
    this.display.next('open')
  }

  close() {
    this.display.next('close')
    // this.store.dispatch(AppActions.setVideoUrl({ videoUrl: '' }))
  }
}
