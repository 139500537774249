import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormControl } from '@angular/forms'
import { of } from 'rxjs'
import { Country } from 'src/app/core/@types/country'
import { countries } from 'src/app/core/helpers/countries'
import { GetCountryService } from 'src/app/core/services/get-country.service'

@Component({
  selector: 'app-search-country',
  templateUrl: './search-country.component.html',
  styleUrls: ['./search-country.component.scss']
})
export class SearchCountryComponent implements OnInit {
  initialCountryCode = 'CD'
  @Input() placeholder: string = ''

  @Input() control: FormControl = new FormControl()
  @Output() setSelectedCountry: EventEmitter<Country> =
    new EventEmitter<Country>()

  @Input() isDarkTheme = of(false)

  countriesList: Country[] = []
  selectedCountry!: Country

  showSearchPanel = false

  constructor(private getCountryService: GetCountryService) {}

  ngOnInit(): void {
    this.getCountries()
  }

  getCountries() {
    this.countriesList = countries

    this.getCountryService.getCountry().subscribe((res) => {
      this.initialCountryCode = res.country
      const country = this.countriesList.filter(
        (country) => country.code === res.country
      )[0]
      this.selectCountry(country)
      this.toggleSearchPanel()
    })
  }

  selectCountry(country: Country) {
    this.toggleSearchPanel()
    this.selectedCountry = country
    this.initialCountryCode = ''
    this.setSelectedCountry.emit(country)
    this.control.setValue(this.selectedCountry.name)
  }

  toggleSearchPanel() {
    this.showSearchPanel = !this.showSearchPanel
  }
}
