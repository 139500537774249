export const environment = {
  firebase: {
    projectId: 'tutorat-393509',
    appId: '1:949916752416:web:8fc97104a759869518ba45',
    storageBucket: 'tutorat-393509.appspot.com',
    locationId: 'europe-west2',
    apiKey: 'AIzaSyCzogTlLXuCICkLat0tr0CZsuOnUMNpGnU',
    authDomain: 'tutorat-393509.firebaseapp.com',
    messagingSenderId: '949916752416'
  },
  production: false,
  BASE_URL: 'https://api.tutor.dev.152.53.54.190.sslip.io',
  // BASE_URL: 'http://localhost:3000',
  APP_ID: '2015788121',
  SERVER_SECRET: '5b96be9178d824dd9959d05e0be4cf68',
  BASE_URL_FRONT: 'http://localhost:4200',
  GOOGLE_ID:
    '949916752416-6h0rfmpqgujcfu5to3hh4d7pik47cmlo.apps.googleusercontent.com'
}
