export enum TokenKeys {
  ACCESS_TOKEN = 'accessToken',
  REFRESH_TOKEN = 'refreshToken'
}

export enum UserKeys {
  CURRENT_USER = 'currentUser',
  TUTOR_PROFILE = 'tutorProfile',
  LANG = 'LANG',
  IS_DARK_THEME = 'isDarkTheme',
  TO_BE_VERIFIED = 'emailOrPhoneNumber',
  SETTINGS_INDEX = 'settingsIndex',
  CURRENT_PEER_CHAT = 'currentPeerChat'
}

export enum TutorProfileKeys {
  DRAFT_PERSONAL_INFO = 'personalInfo',
  DRAFT_EXPERIENCE = 'experience',
  DRAFT_ID_CARD = 'idCard',
  DRAFT_DIPLOMAS = 'diplomas',
  DRAFT_VIDEO = 'video',
  DRAFT_VIDEO_THUMBNAIL = 'thumbnail',
  DRAFT_VIDEO_DESCRIPTION = 'videoDescription',
  CURRENT_STEP = 'currentStep',
  COURSES = 'courses',
  EXPERIENCES = 'experiences',
  STUDIES = 'studies'
}

export const OTP_LENGHT = 6
export const INVALID_2FA_CODE = 'Invalid 2FA code.'
