<div
  class="select"
  *ngIf="countriesList.length > 0 && selectedCountry"
  [class.darktheme]="isDarkTheme | async"
>
  <div class="field">
    <div class="left" (click)="toggleSearchPanel()">
      <img
        src="https://flagcdn.com/w320/{{
          selectedCountry.code | lowercase
        }}.png"
      />

      <div>
        <i class="fa-solid fa-chevron-down"></i>
      </div>
    </div>
    <div class="right">
      <input
        type="text"
        [placeholder]="placeholder"
        [formControl]="control"
        readonly
      />
    </div>
  </div>
  <div class="countries" *ngIf="showSearchPanel">
    <input
      [(ngModel)]="initialCountryCode"
      [ngModelOptions]="{ standalone: true }"
      type="search"
      placeholder="{{ 'shared.inputs.search' | translate }}"
    />
    <ng-container *ngFor="let country of countriesList">
      <div
        class="country"
        *ngIf="
          initialCountryCode === '' ||
          country.name
            .toLowerCase()
            .includes(initialCountryCode.toLowerCase()) ||
          country.code.toLowerCase().includes(initialCountryCode.toLowerCase())
        "
        (click)="selectCountry(country)"
      >
        <div class="countryFlag">
          <img
            [width]="25"
            [height]="15"
            src="https://flagcdn.com/w320/{{ country.code | lowercase }}.png"
          />
        </div>
        <div class="countryName">
          <div class="name">
            {{ country.name }}
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
