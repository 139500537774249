import { createAction, props } from '@ngrx/store'
import { TokenPair } from 'src/app/features/auth/@types/token-pair'
import { Media } from '../@types/media'
import { CurrentUser } from 'src/app/features/home/@types/current-user'
import { ISession } from 'src/app/features/home/@types/sessions'
import { Tutor } from 'src/app/features/home/@types/tutor'
import { Experience } from 'src/app/features/profile-completion/@types/experience'
import { Study } from 'src/app/features/profile-completion/@types/study'
import {
  ScheduleDay,
  SelectedTimeSlot
} from 'src/app/features/home/@types/schedule'
import { PeerChat } from '../../features/home/@types/chat'

export const tokens = createAction(
  '[After Successful Login Or Successful Refresh Token]  ',
  props<{ tokens: TokenPair }>()
)

export const switchTheme = createAction(
  '[User change the app theme] in app settings',
  props<{ isDarkTheme: boolean }>()
)

export const setMedia = createAction(
  '[User select an image or a video] in the whole app',
  props<{ media: Media | null }>()
)

export const setCurrentUser = createAction(
  '[Home page when the app loads] in the whole app',
  props<{ user: CurrentUser | null }>()
)

export const setSelectedSessions = createAction(
  '[Calendar] user select a date',
  props<{ sessions: ISession[] }>()
)

export const setTutors = createAction(
  '[Tutors Page] user load tutors',
  props<{ tutors: Tutor[] }>()
)

export const initiateSearchTutor = createAction(
  '[Search on Tutor page] user handle search',
  props<{ isSearchingTutor: boolean }>()
)

export const isCreatingPassword = createAction(
  '[User complete otp verification] on reset password',
  props<{ isCreatingPassword: boolean }>()
)

export const addExperiences = createAction(
  '[Complete Profile Tutor] user save a experience',
  props<{ experiences: Experience[] }>()
)

export const addStudies = createAction(
  '[Complete Profile Tutor] user save a study',
  props<{ studies: Study[] }>()
)

export const editExperience = createAction(
  '[Complete Profile Tutor] user edit an experience',
  props<{ isEditingExperiences: boolean }>()
)

export const editStudy = createAction(
  '[Complete Profile Tutor] user edit a study',
  props<{ isEditingStudy: boolean }>()
)

export const updateExperiences = createAction(
  '[Complete Profile Tutor] user update by adding of updating a study',
  props<{ performUpdate: boolean }>()
)

export const addSchedule = createAction(
  '[Tutor add a Time Slot] user create a time slot',
  props<{ schedules: ScheduleDay[] }>()
)

export const setSelectedTimeSlot = createAction(
  '[On Schedule Component] user select a time slot',
  props<{ selectedTimeSlot: SelectedTimeSlot | null }>()
)

export const setCurrentChatPeer = createAction(
  '[Chat Page] user select a peer',
  props<{ currentChatPeer: PeerChat | null }>()
)
