import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { map } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class GetCountryService {
  constructor(private http: HttpClient) {}

  getCountry() {
    return (
      this.http
        .get('https://ipinfo.io/json?token=1950c1a016b22d')
        //eslint-disable-next-line @typescript-eslint/no-explicit-any
        .pipe(map((res: any) => res))
    )
  }
}
