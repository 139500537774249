import { Injectable } from '@angular/core'
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http'
import { catchError, Observable, switchMap, throwError } from 'rxjs'
import { Router } from '@angular/router'
import { TokenKeys } from '../constants/constants'
import { environment } from 'src/environments/environment'
import { AuthService } from 'src/app/features/auth/services/auth.service'
import { LocalStorageService } from '../services/localStorage.service'

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  refresh = false

  constructor(
    private authService: AuthService,
    private router: Router,
    private localStorageService: LocalStorageService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const req = request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.localStorageService.getItem(
          TokenKeys.ACCESS_TOKEN
        )}`
      }
    })

    const refreshTokenUrl = `${environment.BASE_URL}/auth/refresh-tokens`
    if (req.url === refreshTokenUrl) {
      const requestWithAuthHeader = req.clone({
        setHeaders: {
          Authorization: `Bearer ${this.localStorageService.getItem(
            TokenKeys.REFRESH_TOKEN
          )}`
        }
      })
      return next.handle(requestWithAuthHeader)
    }

    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        if (
          err.status === 401 &&
          !this.refresh &&
          !req.url.includes('/auth/')
        ) {
          this.refresh = true
          return this.authService.refreshTokens().pipe(
            switchMap((res) => {
              this.localStorageService.setItem(
                TokenKeys.ACCESS_TOKEN,
                res.accessToken
              )
              this.localStorageService.setItem(
                TokenKeys.REFRESH_TOKEN,
                res.refreshToken
              )

              return next.handle(
                request.clone({
                  setHeaders: {
                    Authorization: `Bearer ${res.accessToken}`
                  }
                })
              )
            }),
            catchError((err: HttpErrorResponse) => {
              return throwError(() => this.router.navigate(['/auth/login']))
            })
          )
        }

        this.refresh = false
        return throwError(() => err)
      })
    )
  }
}
