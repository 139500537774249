import { isPlatformBrowser } from '@angular/common'
import { Component, Inject, PLATFORM_ID } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-switch-lang',
  templateUrl: './switch-lang.component.html',
  styleUrls: ['./switch-lang.component.scss']
})
export class SwitchLangComponent {
  constructor(
    public translate: TranslateService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  switchLang = (lang: string) => {
    this.translate.use(lang)
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('LANG', lang)
    }
  }
}
