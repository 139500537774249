import { NgModule } from '@angular/core'
import { PreloadAllModules, RouterModule, Routes } from '@angular/router'
import { AuthGuard } from './core/guards/auth.guard'

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('./features/auth/auth.module').then((module) => module.AuthModule)
  },
  {
    path: 'complete-profile',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./features/profile-completion/profile-completion.module').then(
        (module) => module.ProfileCompletionModule
      )
  },
  {
    path: 'app',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./features/home/home.module').then((module) => module.HomeModule)
  },
  {
    path: '',
    loadChildren: () =>
      import('./features/landing-page/landing-page.module').then(
        (module) => module.LandingPageModule
      )
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  }
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledNonBlocking',
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
