import { createReducer, on } from '@ngrx/store'
import { TokenPair } from 'src/app/features/auth/@types/token-pair'
import { AppActions } from '.'
import { Media } from '../@types/media'
import { CurrentUser } from 'src/app/features/home/@types/current-user'
import { ISession } from 'src/app/features/home/@types/sessions'
import { Tutor } from 'src/app/features/home/@types/tutor'
import { Experience } from 'src/app/features/profile-completion/@types/experience'
import { Study } from 'src/app/features/profile-completion/@types/study'
import {
  ScheduleDay,
  SelectedTimeSlot
} from 'src/app/features/home/@types/schedule'
import { PeerChat } from '../../features/home/@types/chat'

export const appSelector = 'root'

export interface AppState {
  isDarkTheme: boolean
  isCreatingPassword: boolean
  tokens: TokenPair | null
  media: Media | null
  currentUser: CurrentUser | null
  selectedSessions: ISession[]
  experiences: Experience[]
  studies: Study[]
  isEditingExperiences: boolean
  isEditingStudy: boolean
  updateExperiences: boolean
  filteredTutors: Tutor[]
  isSearchingTutor: boolean
  schedules: ScheduleDay[]
  selectedTimeSlot: SelectedTimeSlot | null
  currentChatPeer: PeerChat | null
}

export const initialAppState: AppState = {
  isDarkTheme: true,
  isCreatingPassword: false,
  tokens: null,
  media: null,
  currentUser: null,
  selectedSessions: [],
  experiences: [],
  isEditingExperiences: false,
  studies: [],
  isEditingStudy: false,
  updateExperiences: true,
  filteredTutors: [],
  isSearchingTutor: false,
  schedules: [],
  selectedTimeSlot: null,
  currentChatPeer: null
}

export const appReducer = createReducer(
  initialAppState,

  on(AppActions.tokens, (state, action) => {
    return {
      ...state,
      tokens: action.tokens
    }
  }),
  on(AppActions.switchTheme, (state, action) => {
    return {
      ...state,
      isDarkTheme: action.isDarkTheme
    }
  }),

  on(AppActions.setMedia, (state, action) => {
    return {
      ...state,
      media: action.media
    }
  }),

  on(AppActions.setCurrentUser, (state, action) => {
    return {
      ...state,
      currentUser: action.user
    }
  }),

  on(AppActions.isCreatingPassword, (state, action) => {
    return {
      ...state,
      isCreatingPassword: action.isCreatingPassword
    }
  }),

  on(AppActions.setSelectedSessions, (state, action) => {
    return {
      ...state,
      selectedSessions: action.sessions
    }
  }),

  on(AppActions.addExperiences, (state, action) => {
    return {
      ...state,
      experiences: action.experiences
    }
  }),

  on(AppActions.editExperience, (state, action) => {
    return {
      ...state,
      isEditingExperiences: action.isEditingExperiences
    }
  }),

  on(AppActions.addStudies, (state, action) => {
    return {
      ...state,
      studies: action.studies
    }
  }),

  on(AppActions.editStudy, (state, action) => {
    return {
      ...state,
      isEditingStudy: action.isEditingStudy
    }
  }),

  on(AppActions.updateExperiences, (state, action) => {
    return {
      ...state,
      updateExperiences: action.performUpdate
    }
  }),

  on(AppActions.setTutors, (state, action) => {
    return {
      ...state,
      filteredTutors: action.tutors
    }
  }),

  on(AppActions.initiateSearchTutor, (state, action) => {
    return {
      ...state,
      isSearchingTutor: action.isSearchingTutor
    }
  }),

  on(AppActions.addSchedule, (state, action) => {
    return {
      ...state,
      schedules: action.schedules
    }
  }),

  on(AppActions.setSelectedTimeSlot, (state, action) => {
    return {
      ...state,
      selectedTimeSlot: action.selectedTimeSlot
    }
  }),

  on(AppActions.setCurrentChatPeer, (state, action) => {
    return {
      ...state,
      currentChatPeer: action.currentChatPeer
    }
  })
)
