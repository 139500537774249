<ng-container *ngIf="display$ | async as display">
  <section
    class="container-modal"
    [class.open]="display === 'open'"
    (click)="close()"
  >
    <div class="close" (click)="close()">
      <img src="assets/icons/close-dark.svg" alt="Close" />
    </div>

    <div
      (click)="$event.stopPropagation()"
      class="modal"
      *ngIf="media$ | async as media"
      [class.fullHeight]="media.mediaType === 'file'"
    >
      <video *ngIf="media.mediaType === 'video'" autoplay controls>
        <source [src]="media.url" />
        {{ 'shared.labels.videoNotSupported' | translate }}
      </video>
      <img *ngIf="media.mediaType === 'image'" [src]="media.url" />
      <ngx-doc-viewer
        *ngIf="media.mediaType === 'file'"
        [url]="media.url"
        viewer="url"
      >
      </ngx-doc-viewer>
    </div>
  </section>
</ng-container>
