import { Injectable } from '@angular/core'
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http'
import { Observable, of } from 'rxjs'
import { finalize } from 'rxjs/operators'
import { createHash } from 'crypto'

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  private pendingRequests: Map<string, Observable<HttpEvent<any>>> = new Map()

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const requestKey = this.getRequestKey(req)

    if (this.pendingRequests.has(requestKey)) {
      return this.pendingRequests.get(requestKey)!
    }
    const request$ = next
      .handle(req)
      .pipe(finalize(() => this.pendingRequests.delete(requestKey)))

    this.pendingRequests.set(requestKey, request$)
    return request$
  }

  // eslint-disable-next-line class-methods-use-this
  private getRequestKey(req: HttpRequest<any>): string {
    const { method, urlWithParams, body } = req
    const bodyString = JSON.stringify(body || {})
    return `${method} ${urlWithParams} ${bodyString}`
  }
}
