import { createFeatureSelector, createSelector } from '@ngrx/store'
import { fromAppState } from '.'
import { AppState } from './app.reducers'

export const selectAppState = createFeatureSelector<AppState>(
  fromAppState.appSelector
)

export const getTokens = createSelector(selectAppState, (state) => state.tokens)

export const getAppTheme = createSelector(
  selectAppState,
  (state) => state.isDarkTheme
)

export const getSelectedMedia = createSelector(
  selectAppState,
  (state) => state.media
)

export const getCurrentUser = createSelector(
  selectAppState,
  (state) => state.currentUser
)

export const getSelectedSession = createSelector(
  selectAppState,
  (state) => state.selectedSessions
)

export const isCreatingPassword = createSelector(
  selectAppState,
  (state) => state.isCreatingPassword
)

export const getExperiences = createSelector(
  selectAppState,
  (state) => state.experiences
)

export const getStudies = createSelector(
  selectAppState,
  (state) => state.studies
)

export const isEditingExperiences = createSelector(
  selectAppState,
  (state) => state.isEditingExperiences
)

export const isEditingStudy = createSelector(
  selectAppState,
  (state) => state.isEditingStudy
)

export const performUpdate = createSelector(
  selectAppState,
  (state) => state.updateExperiences
)

export const getFilteredTutors = createSelector(
  selectAppState,
  (state) => state.filteredTutors
)

export const isSearchingTutor = createSelector(
  selectAppState,
  (state) => state.isSearchingTutor
)

export const getSchedule = createSelector(
  selectAppState,
  (state) => state.schedules
)

export const getSelectedTimeSlot = createSelector(
  selectAppState,
  (state) => state.selectedTimeSlot
)

export const getCurrentPeerChat = createSelector(
  selectAppState,
  (state) => state.currentChatPeer
)
